 
<template>
<!-- <CompanyPolicy></CompanyPolicy> -->
<div class="col-xxl-12 mb-5">
    <!-- <ECShopAdvisory></ECShopAdvisory> -->
  </div>
  <div v-show="loading"> 
 	<el-skeleton :rows="10" animated />
  </div>	
  <div class="container" v-show="!loading && packagetype !== 'BIBO'"> 
    <div id="genealogy-pps-chart">
    <GChart  
      :resizeDebounce="500"
      :settings="{ packages: ['orgchart'], callback: ()=>{this.drawChart()} }"
      :events="chartEvents"
      type="OrgChart"
      :data="chartData"  
      ref="gChart" 

    />
    </div>
	 
 
	<div  class="card mt-10">
					<div class="card-header cursor-pointer">
						<!--begin::Card title-->
						<div class="card-title m-0">
							<h3 class="fw-bolder m-0"></h3> 
							<!--begin::Actions-->
							<div class="d-flex align-items-center py-1"> 
		
								<button 
									@click="reset();"
									class="btn btn-sm btn-secondary" 
								>
								RESET
								</button>
								<button 
									@click="confirmPPSSetup(1);"
									class="btn btn-sm btn-primary" 
									v-if="proc==1"
								>
								PROCEED
								</button>  
								<button 
									@click="confirmPPSSetup(2);"
									class="btn btn-sm btn-danger" 
									v-if="proc==2"
								>
								I,CONFIRM THIS STRUCTURE
								</button>  
							</div> 
						</div>  
					</div>  
	</div>
  </div>
   
</template>

<script  >
import { GChart } from 'vue-google-charts'
 
import { ppssetup, ppssetupconfirm} from '@/api/network.api'
import Swal from "sweetalert2/dist/sweetalert2.min.js";  
import {BlockUI} from 'vue-blockui';
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";

  export default {
    components: {
      GChart,
	//   CompanyPolicy,
	//   ECShopAdvisory
    },
    data () {
      return {
              // Array will be automatically processed with visualization.arrayToDataTable function
			chartData : null, 
			ppsBackground: "#4caf50",
			currentAccount: "",
			emptyNodeBackground: "gray",
			notPPSBackground: "#2196f3",
			loading: true,
			proc: 1,
			children: [],
			downlines: [], 
			chartEvents: {
				select : () => {     
					console.log('select');      
				},
				click: () => {
					console.log('click');  
				} 
			},
			msg: 'Welcome to Your Vue.js App',
			packagetype: ""  
			  
      }
    },
    methods: { 
		 
		reset(){
			this.proc = 1;
			window.location.href =   "/#/networkmenu/ppssetup";
			location.reload();
		},
          drawChart() {
		  
               // console.log(this.downlines);
			//	if (this.chartData == null) {   	
					this.chartData = new google.visualization.DataTable();
					this.chartData.addColumn('string', 'Name')
					this.chartData.addColumn('string', 'Manager')
					this.chartData.addColumn('string', 'ToolTip')
			//	} 
			
   
			let topaccountdiv = "";

			topaccountdiv = topaccountdiv + '<div>';
			topaccountdiv = topaccountdiv + '<img src="' +  this.downlines[1].avatar  + '"  style="width:48px; height:48px;">';
			topaccountdiv = topaccountdiv + '</div>';
			topaccountdiv = topaccountdiv +  this.downlines[1].usid + '<br/>'; 

			
			this.chartData.addRow(
				[ {v:   "1", f: topaccountdiv } ,'','']   
			); 

			for(let i = 2; i <= 31; i++) {
				let parent = "";
				if(i > 1) {

					if((i % 2) != 0) {
						parent =  (i - 1) / 2; 
					} else { 
						parent =  i / 2;
					}
				}  
 
				let label = "";
				let dragObj = "";
				if(this.downlines[i] !== undefined) {
					let draggableClass = ""; 
					 
					dragObj = dragObj + '<div headno="' + i + '" id="draggable'+ this.downlines[i].usid +'" draggable="true" class="occupied draggable"     >';
						dragObj = dragObj + '<img src="' +  this.downlines[i].avatar  + '"  style="width:58px; height:58px;">';
					dragObj = dragObj + '</div> <br/>' ;
					label = dragObj; 
 
				} else {

					dragObj = dragObj + '<div headno="' + i + '" id="draggable'+ i +'" draggable="true"  class="draggable"  style="width:58px; height:58px;" >'; 
	 
					dragObj = dragObj + '</div> <br/>' ;
					label = dragObj; 
				}



				this.chartData.addRow(
					[ {v: i + "", f: label + i} , parent + "", '']   
				); 
 

				if(this.downlines[i] !== undefined) {
					if(this.downlines[i].isPPS === 1){  
						this.chartData.setRowProperty(i-1, 'style', 'background: ' + this.ppsBackground + ' !important;');	//PPS background 
					}else {
						this.chartData.setRowProperty(i-1, 'style', 'background: ' + this.notPPSBackground + ' !important;');	 //NOT PPS background
					} 
				} else {
					this.chartData.setRowProperty(i-1, 'style', 'background: ' + this.emptyNodeBackground + ' !important;');	 //NOT PPS background
				}

			}
			
            var chart = new google.visualization.OrgChart(document.getElementById('genealogy-pps-chart')); 
			 chart.draw(this.chartData, {allowHtml:true, allowCollapse:false, size:'medium' });
  
 
			var self = this;
			new google.visualization.events.addListener(chart, 'select', function() { 
			 
					 
					var selection = chart.getSelection();

					self.children = [];
					if (selection.length > 0) {
					// alert(data.getValue(selection[0].row, 0));
						getChilds(selection[0].row) // start recursive function with selected index 
						console.log(self.children);
					}
					
					function getChilds(index){ // recursive function, adds children of index (row)
						var childs=chart.getChildrenIndexes(index); // get children indexes of current index
						for(var i = 0; i < childs.length;i++){ // for each children 
							var t = self.chartData.getFormattedValue(childs[i],0);    
							let draggable_headno = t.substr(t.indexOf("headno=\"") + 8,t.indexOf("id=\"draggable")-15); 
							if (draggable_headno != "")
							{ 
								if(self.downlines[draggable_headno] !== undefined) { 
									self.children.push({   headno:  draggable_headno}); //id of the draggable 
								}
							}  
							//children.push(data.getValue(childs[i],0)) // add the title
							getChilds(childs[i]) // and call the function with this children index, to get their children and so on >> this is recursive
						}
					}  
			});



			let items = document.querySelectorAll('.draggable');
			items.forEach(function(item) {
				item.addEventListener('dragstart', function(e){ 
					//this.style.opacity = '0.4'; 
					let dragSrcEl = this; 
  					e.dataTransfer.setData("text", dragSrcEl.getAttribute("headno"));

				});
				item.addEventListener('drop', function(e){
					console.log("drop");
					e.preventDefault();
					let dragTargetEl = this; 
					var targetHeadno = dragTargetEl.getAttribute("headno");
					var sourceHeadno = e.dataTransfer.getData("text");

					//if target already contains head
					if(self.downlines[targetHeadno] !== undefined) {
							self.drawChart();
							return;
					}

					//IF NOT PPS, then dont allow drag
					if(self.downlines[sourceHeadno] !== undefined && self.downlines[sourceHeadno].isPPS !== 1) {
							self.drawChart();
							return;
					}
					 
					 var newstructure=[];
					 
					 newstructure[sourceHeadno] = {
							 o: sourceHeadno,
							 n: targetHeadno
					 };

					 let xdownlines= []; 
					 xdownlines = self.downlines;
					 
					self.downlines[targetHeadno] =  self.downlines[sourceHeadno];
					self.downlines[sourceHeadno] = undefined;

					 for(var i = 0; i < self.children.length; i++) {
						 
						 var parentHeadno = 0;
						 var newArrPos = 0;
						 if(self.children[i].headno % 2 != 0 ) {
							 parentHeadno = (self.children[i].headno - 1) /2; 
						 } else {
							 parentHeadno = (self.children[i].headno) /2;
						 }
						 
						 if(self.children[i].headno % 2 != 0 ) { 
							 newArrPos = newstructure[parentHeadno].n;
							 newArrPos = (newArrPos * 2) + 1;
						 } else {
							 newArrPos = newstructure[parentHeadno].n;
							 newArrPos = (newArrPos * 2);
						 } 
						 newstructure[self.children[i].headno] = {
								 o: self.children[i].headno,
								 n: newArrPos
						 }; 
						 if(self.downlines[newArrPos] !== undefined) {
							 //droppable already occupied
							 self.dowlines =  xdownlines;
							 self.drawChart(); 
							return false;
						 } 
						 self.downlines[newArrPos]  = self.downlines[self.children[i].headno];
						 self.downlines[self.children[i].headno] = undefined; 
						 self.drawChart();
						 console.log(self.downlines);
						  
					 }
 
				});
				item.addEventListener('dragover', function(e){ 
					e.preventDefault(); 
				}); 
			});
		 	
			 this.loading= false;
          },

		  
 

		async getGenealogy(){ 
					const response = await ppssetup();  
					if(response.data.pps !== undefined && response.data.pps.structure !== undefined) {
						this.currentAccount = response.data.pps.currentAccount;
						let structure =  response.data.pps.structure;
						for(let i = 0; i < structure.length; i++) {
							this.downlines[structure[i].positionno] = {
									usid: structure[i].usid,
									isPPS:structure[i].isPPS,
									avatar: structure[i].avatar
							};
						}
					}
					this.drawChart();
		},
		async confirmPPSSetup(proc){ 
				this.loading = true;
				this.proc = proc;

					for(var i = 1; i <=31; i++) {
						if(this.downlines[i] !== undefined) {
							var position = "";
							var uplineno = 0;
							var upline = "";
							if(i % 2 != 0) {
								position =  "R";
								uplineno = (i - 1) /2;
							}else {
								position = "L";
								uplineno = (i) /2;
							}
							
							if(this.downlines[uplineno] !== undefined) { 
								upline  = this.downlines[uplineno].usid ;
							}
							this.downlines[i].position = position;
							this.downlines[i].upid = upline;
						}
					}


				let param = {
					downlines : this.downlines,
					proc: proc	
				};
				const response = await ppssetupconfirm(param); 
				
				this.loading = false;
				if (response.data.result == "SUCCESS") { 
 
    		    	if(proc === 2){ 
						Swal.fire({
							title: "",
							text: response.data.message,
							icon: "success",
							confirmButtonClass: "btn btn-secondary"
						}); 
						return;
    		    	}
					this.proc = 2;
    		    	
    			} else if(response.data.result == "FAILED"){
					Swal.fire({
						title: "",
						text: response.data.message,
						icon: "error",
						confirmButtonClass: "btn btn-secondary"
					}); 
					return;
    			}

		} 

      },
	mounted(){  
		   setCurrentPageTitle("PPS Setup");
		   this.packagetype = localStorage.getItem("packagetype");
		   this.getGenealogy();   
	},
	watch: { 
      	chartData: function(newVal, oldVal) { // watch it
          console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }
      }


  }

</script>

<style>
  table {
      border-collapse: inherit;
      border-spacing: 0;
  }
  .google-visualization-orgchart-node {
	  border: 2px solid #8fb2c3 !important;
	  background-color: none !important;
	  background:none !important;
  }
  
</style>