import request from '@/utils/request'



export function genealogyapi(id) {
    return request({ url: 'vuegenealogy/' + id, method: 'get'})
  } 


 
export function ppssetup() {
  return request({ url: 'vppssetup', method: 'post', data: {'x': 'x'} })
} 


export function ppssetupconfirm(param) {
  return request({ url: 'ppssetupconfirm', method: 'post', data: param })
} 


export function hasOVIPackage() {
  return request({ url: 'vhasovipackage', method: 'get'})
} 
